.dashboard-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: var(--background);
}

.dashboard-container > div > h1 {
    margin: 1%;
    padding: 3%;
    font-family: var(--alt-font);
    color: var(--primary);
}

.dashboard-card {
    width: 40%;
    box-shadow: 0em 0em 0.5em 0.3em #888888;
    min-width: 20em;
    background-color: var(--surface);
    padding: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 2em;
    font-family: var(--alt-font);
    color: var(--on-surface);
}

.dashboard-card > h4 {
    font-size: large;
    text-align: center;
    padding: 0.2em;
}

.dashboard-card > span {
    font-weight: bold;
    padding-bottom: 0.1em;
}

.dashboard-card > p {
    padding-bottom: 1em;
    padding-left: 0.5em;
}