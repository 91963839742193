.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    min-width: 100%;
    min-height: 100vmax;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 800ms;
}

.hidden {
   opacity: 0;
   min-height: 0;
   height: 0;
   overflow: hidden;
}

.overlay-card {
    width: 40%;
    /* box-shadow: 0em 0em 0.5em 0.3em #888888; */
    min-width: 20em;
    background-color: var(--surface);
    padding: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 1em;
    font-family: var(--alt-font);
    color: var(--on-surface);
}

.overlay-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}