.docket-container {
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--background);
}

.docket-item-container{
    min-width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 10px;
    column-gap: 10px;
}

.docket-container  > h1 {
    color: var(--primary);
}

@media only screen and (max-width: 600px) {
    
    .docket-item-container{
        min-width: 70%;
        display: flex;
        flex-direction: column;
    }

  }