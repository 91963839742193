.login-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background); 
}

.login-card {
    width: 40%;
    min-width: 20em;

    box-shadow: 0em 0em 1.5em 0.8em #888888;

    background-color: var(--surface);
}

.login-card-header {
    display: flex;
    justify-content: center;
    padding: 1em;

    color: var(--on-primary);
    background-color: var(--primary);
    font-family: var(--base-font);
}

.login-inputs {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
}

.login-input-item {
    padding: 1em;
    margin: 1em;
    border: 2px solid var(--custom-gray);
    border-radius: 0.5em;
    font-family: var(--base-font);
    font-size: 1em;
    letter-spacing: 0.1em;
    outline: none;
}

.login-input-item:focus{
    border: 2px solid var(--secondary);
    border-radius: 0.5em;
}

.login-input-item::placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.login-login-label {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 0.8em;
    margin-left: 1.5em;
}

.login-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}

.login-signup-link {
    text-align: center;
}

.login-signup-link > a {
   font-weight: bold;
   color: var(--on-surface);
   text-decoration: none;
}

.login-signup-link > a:hover{
    color: var(--secondary);
}
