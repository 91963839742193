.custominput-inputs {
    display: flex;
    flex-direction: column;
    min-height: auto;
}

.custominput-input-item {
    padding: 0.8rem;
    margin: 0.5rem;
    border: 2px solid var(--custom-gray);
    border-radius: 0.5rem;
    font-family: var(--base-font);
    font-size: 1rem;
    letter-spacing: 0.1rem;
    outline: none;
}

.custominput-input-item:focus{
    border: 2px solid var(--secondary);
    border-radius: 0.5em;
}

.custominput-input-item::placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-label {
    font-family: var(--alt-font);
    font-weight: bold;
    font-size: 0.9em;
    margin-left: 1.5em;
}

@media screen and (max-height: 600px) {
    .custominput-input-item {
        padding: 0.5rem;
        margin: 0.5rem;
        border: 2px solid var(--custom-gray);
        border-radius: 0.5rem;
        font-family: var(--base-font);
        font-size: 1rem;
        letter-spacing: 0.1rem;
        outline: none;
    }
}