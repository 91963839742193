.btn-primary {
    color: var(--on-primary);
    background-color: var(--primary); 
}

.btn-primary:hover {
    background-color: var(--primary-variant);
}

.btn-secondary { 
    color: var(--on-secondary);
    background-color: var(--secondary);
}

.btn-secondary:hover { 
    background-color: var(--secondary-variant);
}

.btn-custom-button {
    display: flex;
    flex-direction: column;
}

.btn-custom-button > div {
    display: flex;
    justify-content: center ;
    font-family: var(--base-font);
    font-weight: bold;
    font-size: medium;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.2em;
    letter-spacing: 0.1em;
    cursor: pointer;
}
