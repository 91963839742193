.nav-container {
    display: flex;
    background-color: var(--primary);
    color: var(--on-primary);
    padding: 5px;
}

.nav-menuicon {
    font-size: 2em;
    padding: 5px;
    margin-left: 1%;
}

.nav-heading {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
}

.nav-sidebar {
    display: flex;
    flex-direction: column;
    background-color: var(--primary);
    min-width: 200px;
    width: 13%;
    height: 100%;
    top: 0;
    position: fixed;
    left: -100%;
    transition: 600ms;
}

.nav-sidebar.active {
    left: 0;
    transition: 450ms;
}

.nav-sidebar > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
}

.nav-sidebar-header {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    height: 40px;
    background-color: var(--primary);
}

.nav-sidebar-header > h4 {
    align-self: center;
    font-family: var(--alt-font);
    color: var(--on-primary);
}

.nav-sidebar-header .icon {
    align-self: center;
    color: var(--on-primary);
}

.nav-sidebar > ul > li {
   padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    
}

.nav-sidebar > ul > li > a {
    display: flex;
    text-decoration: none;
    font-family: var(--alt-font);
    color: var(--on-primary);
}

.nav-sidebar > ul > li:hover {
    background-color: var(--primary-variant);
}

