@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap);
* {
  margin: 0
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
    --primary: #1e88ef;
    --primary-variant: #1565c0;
    --secondary: #ff9800;
    --secondary-variant: #f57c00;
    --background: #eceff1;
    --surface: #ffffff;
    --error: #c62828;
    --on-primary: #ffffff;
    --on-secondary: #000000;
    --on-background: #ffffff;
    --on-surface: #292a2b;
    --on-error: #ffffff;

    --custom-gray: #C7CACC;

    --base-font:'Raleway', sans-serif;
    --alt-font: 'Lato', sans-serif;
}

html,
body, #root, .app {
    height: 100%;
}

.login-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background); 
}

.login-card {
    width: 40%;
    min-width: 20em;

    box-shadow: 0em 0em 1.5em 0.8em #888888;

    background-color: var(--surface);
}

.login-card-header {
    display: flex;
    justify-content: center;
    padding: 1em;

    color: var(--on-primary);
    background-color: var(--primary);
    font-family: var(--base-font);
}

.login-inputs {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
}

.login-input-item {
    padding: 1em;
    margin: 1em;
    border: 2px solid var(--custom-gray);
    border-radius: 0.5em;
    font-family: var(--base-font);
    font-size: 1em;
    letter-spacing: 0.1em;
    outline: none;
}

.login-input-item:focus{
    border: 2px solid var(--secondary);
    border-radius: 0.5em;
}

.login-input-item::-webkit-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.login-input-item:-ms-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.login-input-item::-ms-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.login-input-item::placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.login-login-label {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 0.8em;
    margin-left: 1.5em;
}

.login-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}

.login-signup-link {
    text-align: center;
}

.login-signup-link > a {
   font-weight: bold;
   color: var(--on-surface);
   text-decoration: none;
}

.login-signup-link > a:hover{
    color: var(--secondary);
}

.btn-primary {
    color: var(--on-primary);
    background-color: var(--primary); 
}

.btn-primary:hover {
    background-color: var(--primary-variant);
}

.btn-secondary { 
    color: var(--on-secondary);
    background-color: var(--secondary);
}

.btn-secondary:hover { 
    background-color: var(--secondary-variant);
}

.btn-custom-button {
    display: flex;
    flex-direction: column;
}

.btn-custom-button > div {
    display: flex;
    justify-content: center ;
    font-family: var(--base-font);
    font-weight: bold;
    font-size: medium;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.2em;
    letter-spacing: 0.1em;
    cursor: pointer;
}

.custominput-inputs {
    display: flex;
    flex-direction: column;
    min-height: auto;
}

.custominput-input-item {
    padding: 0.8rem;
    margin: 0.5rem;
    border: 2px solid var(--custom-gray);
    border-radius: 0.5rem;
    font-family: var(--base-font);
    font-size: 1rem;
    letter-spacing: 0.1rem;
    outline: none;
}

.custominput-input-item:focus{
    border: 2px solid var(--secondary);
    border-radius: 0.5em;
}

.custominput-input-item::-webkit-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-input-item:-ms-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-input-item::-ms-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-input-item::placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-label {
    font-family: var(--alt-font);
    font-weight: bold;
    font-size: 0.9em;
    margin-left: 1.5em;
}

@media screen and (max-height: 600px) {
    .custominput-input-item {
        padding: 0.5rem;
        margin: 0.5rem;
        border: 2px solid var(--custom-gray);
        border-radius: 0.5rem;
        font-family: var(--base-font);
        font-size: 1rem;
        letter-spacing: 0.1rem;
        outline: none;
    }
}
.dashboard-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: var(--background);
}

.dashboard-container > div > h1 {
    margin: 1%;
    padding: 3%;
    font-family: var(--alt-font);
    color: var(--primary);
}

.dashboard-card {
    width: 40%;
    box-shadow: 0em 0em 0.5em 0.3em #888888;
    min-width: 20em;
    background-color: var(--surface);
    padding: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 2em;
    font-family: var(--alt-font);
    color: var(--on-surface);
}

.dashboard-card > h4 {
    font-size: large;
    text-align: center;
    padding: 0.2em;
}

.dashboard-card > span {
    font-weight: bold;
    padding-bottom: 0.1em;
}

.dashboard-card > p {
    padding-bottom: 1em;
    padding-left: 0.5em;
}
.nav-container {
    display: flex;
    background-color: var(--primary);
    color: var(--on-primary);
    padding: 5px;
}

.nav-menuicon {
    font-size: 2em;
    padding: 5px;
    margin-left: 1%;
}

.nav-heading {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
}

.nav-sidebar {
    display: flex;
    flex-direction: column;
    background-color: var(--primary);
    min-width: 200px;
    width: 13%;
    height: 100%;
    top: 0;
    position: fixed;
    left: -100%;
    transition: 600ms;
}

.nav-sidebar.active {
    left: 0;
    transition: 450ms;
}

.nav-sidebar > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
}

.nav-sidebar-header {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    height: 40px;
    background-color: var(--primary);
}

.nav-sidebar-header > h4 {
    align-self: center;
    font-family: var(--alt-font);
    color: var(--on-primary);
}

.nav-sidebar-header .icon {
    align-self: center;
    color: var(--on-primary);
}

.nav-sidebar > ul > li {
   padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    
}

.nav-sidebar > ul > li > a {
    display: flex;
    text-decoration: none;
    font-family: var(--alt-font);
    color: var(--on-primary);
}

.nav-sidebar > ul > li:hover {
    background-color: var(--primary-variant);
}


.newdocket-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: var(--background);
}

.newdocket-container  > h1 {
    color: var(--primary);
}

.newdocket-card {
    width: 40%;
    box-shadow: 0em 0em 0.5em 0.3em #888888;
    min-width: 20em;
    background-color: var(--surface);
    padding: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 1em;
    font-family: var(--alt-font);
    color: var(--on-surface);
}

.newdocket-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}

.signup-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background); 
}

.signup-card {
    width: 40%;
    min-width: 20em;

    box-shadow: 0em 0em 1.5em 0.8em #888888;

    background-color: var(--surface);
}

.signup-card-header {
    display: flex;
    justify-content: center;
    padding: 1em;

    color: var(--on-primary);
    background-color: var(--primary);
    font-family: var(--base-font);
}

.signup-inputs {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
}

.signup-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}


.docket-container {
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--background);
}

.docket-item-container{
    min-width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.docket-container  > h1 {
    color: var(--primary);
}

@media only screen and (max-width: 600px) {
    
    .docket-item-container{
        min-width: 70%;
        display: flex;
        flex-direction: column;
    }

  }
.docket-item {
    padding: 10px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    row-gap: 10px;
   background-color: var(--surface);
   transition: box-shadow 500ms;
   cursor: pointer;
}

.docket-item-icons {
    grid-column: 1 / 3;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
}

.docket-item-icon-style {
    color: var(--surface);
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    border-radius: 20px;
    margin-left: 10px;
    padding: 2px;

    transition: color 400ms, background-color 400ms, border-color 400ms;
}

.docket-item-icon-style:hover {
    color: var(--secondary);
    background-color: var(--surface);
    border-color: var(--surface);
}

.docket-item:hover {
    box-shadow: -1px 0px 10px 3px rgba(168,168,168,1);
}

.docket-date {
    display: flex;
    justify-content: flex-end;
    grid-column: 2 / 3;
    font-size: x-small;
    color: var(--secondary-variant);
}

@media only screen and (max-width: 600px) {
    
   .docket-item {
        margin-top: 10px;
        
    }

  }




.detail-container {
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    background-color: var(--background);
    font-family: var(--alt-font);
}

.detail-container  > h1 {
    align-self: center;
    color: var(--primary);
    margin-top: 5px;
}

.detail-date {
    display: flex;
    padding: 20px 10%;
    justify-content: flex-end;
    align-items: center;
}

.detail-date>h3 {
    margin-right: 10px;
}

.detail-header {
    padding: 20px 10%;
}

.detail-header > table {
    width: 100%;
    border-collapse: collapse;
    
}

.detail-header > table > tbody > * > * {
    border: 1px solid black;
  }

  .detail-header > table > tbody > tr td{
      padding: 5px;
  }

.detail-header > table > tbody > tr td:first-child {
    padding-right: 40px;
    width: 1%;
    white-space: nowrap;
}

.detail-item-container {
    padding: 10px 10%;
}

.detail-item-table > thead > tr > th {
    background-color: var(--secondary);
    padding: 5px;
}

.detail-item-table > tbody > tr:nth-child(even) {
    background-color: #d8e1ed;
}
.detail-item-table > tbody > tr:nth-child(odd) {
    background-color: #ede4d8;
}

.detail-item-table > tbody > tr > td {
    padding: 5px;
}

.detail-item-table {
    width: 100%;
}


.detail-footer {
    display: flex;
    margin: 20px 10%;
    justify-content: flex-end;
}

.selected {
    background-color: rgba(0, 255, 0, 0.39) !important;
}

.detail-exports {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: flex-end;
}



@media only screen and (max-width: 600px) {
    .detail-footer .btn-primary {
        font-size: x-small;
    }
  }
.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    min-width: 100%;
    min-height: 100vmax;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 800ms;
}

.hidden {
   opacity: 0;
   min-height: 0;
   height: 0;
   overflow: hidden;
}

.overlay-card {
    width: 40%;
    /* box-shadow: 0em 0em 0.5em 0.3em #888888; */
    min-width: 20em;
    background-color: var(--surface);
    padding: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 1em;
    font-family: var(--alt-font);
    color: var(--on-surface);
}

.overlay-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}
