.newdocket-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: var(--background);
}

.newdocket-container  > h1 {
    color: var(--primary);
}

.newdocket-card {
    width: 40%;
    box-shadow: 0em 0em 0.5em 0.3em #888888;
    min-width: 20em;
    background-color: var(--surface);
    padding: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 1em;
    font-family: var(--alt-font);
    color: var(--on-surface);
}

.newdocket-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}