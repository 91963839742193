.detail-container {
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    background-color: var(--background);
    font-family: var(--alt-font);
}

.detail-container  > h1 {
    align-self: center;
    color: var(--primary);
    margin-top: 5px;
}

.detail-date {
    display: flex;
    padding: 20px 10%;
    justify-content: flex-end;
    align-items: center;
}

.detail-date>h3 {
    margin-right: 10px;
}

.detail-header {
    padding: 20px 10%;
}

.detail-header > table {
    width: 100%;
    border-collapse: collapse;
    
}

.detail-header > table > tbody > * > * {
    border: 1px solid black;
  }

  .detail-header > table > tbody > tr td{
      padding: 5px;
  }

.detail-header > table > tbody > tr td:first-child {
    padding-right: 40px;
    width: 1%;
    white-space: nowrap;
}

.detail-item-container {
    padding: 10px 10%;
}

.detail-item-table > thead > tr > th {
    background-color: var(--secondary);
    padding: 5px;
}

.detail-item-table > tbody > tr:nth-child(even) {
    background-color: #d8e1ed;
}
.detail-item-table > tbody > tr:nth-child(odd) {
    background-color: #ede4d8;
}

.detail-item-table > tbody > tr > td {
    padding: 5px;
}

.detail-item-table {
    width: 100%;
}


.detail-footer {
    display: flex;
    margin: 20px 10%;
    justify-content: flex-end;
}

.selected {
    background-color: rgba(0, 255, 0, 0.39) !important;
}

.detail-exports {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: flex-end;
}



@media only screen and (max-width: 600px) {
    .detail-footer .btn-primary {
        font-size: x-small;
    }
  }