.docket-item {
    padding: 10px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 10px;
   background-color: var(--surface);
   transition: box-shadow 500ms;
   cursor: pointer;
}

.docket-item-icons {
    grid-column: 1 / 3;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
}

.docket-item-icon-style {
    color: var(--surface);
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    border-radius: 20px;
    margin-left: 10px;
    padding: 2px;

    transition: color 400ms, background-color 400ms, border-color 400ms;
}

.docket-item-icon-style:hover {
    color: var(--secondary);
    background-color: var(--surface);
    border-color: var(--surface);
}

.docket-item:hover {
    box-shadow: -1px 0px 10px 3px rgba(168,168,168,1);
}

.docket-date {
    display: flex;
    justify-content: flex-end;
    grid-column: 2 / 3;
    font-size: x-small;
    color: var(--secondary-variant);
}

@media only screen and (max-width: 600px) {
    
   .docket-item {
        margin-top: 10px;
        
    }

  }



