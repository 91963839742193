
.signup-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background); 
}

.signup-card {
    width: 40%;
    min-width: 20em;

    box-shadow: 0em 0em 1.5em 0.8em #888888;

    background-color: var(--surface);
}

.signup-card-header {
    display: flex;
    justify-content: center;
    padding: 1em;

    color: var(--on-primary);
    background-color: var(--primary);
    font-family: var(--base-font);
}

.signup-inputs {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
}

.signup-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}

